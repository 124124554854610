import {
  Center,
  Flex,
  Grid,
  GridItem,
  Text,
  Box,
  VStack,
  List,
  ListItem,
  Heading,
  Link,
  Button,
  UnorderedList,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import Fontsize from "../../stylingObjects/FontSize";
import biljartTafelLeeg from "../../assets/images/biljarttafelLeeg.png";
import biljartTafelLeegVert from "../../assets/images/biljarttafelLeegVerticaal.png";
import { TournamentForm } from "../parts/TournamentForm";
import React, { ReactNode, useEffect, useState } from "react";
import TournamentplayersTable from "../parts/TournamentplayersTable";

// Declaration of the StyledListItem component
const StyledListItem: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ListItem ml={"4px"}>
      <Text fontFamily={"Arial"} fontWeight={900}>
        {children}
      </Text>
    </ListItem>
  );
};

// check if there is a logged in user and if the user has the role 'tournamentadmin' or 'admin'
// if so, show the table with players
const isTournamentAdmin = () => {
  const user = JSON.parse(localStorage.getItem("User") || "{}");
  // console.log("isTournamentAdmin user: ", user);
  if (user.roles) {
    if (
      user.roles.includes("TOURNAMENTADMIN") ||
      user.roles.includes("ADMIN")
    ) {
      // console.log("user is (tournament)admin");
      // console.log("isTournamentAdmin: true");
      return true;
    }
  }
  // console.log("isTournamentAdmin: false");
  return false;
};

export default function TournamentRegistration() {
  // state variable to store the tournament admin status
  const [isTournamentAdminStatus, setIsTournamentAdminStatus] = useState(false);

  // Call the isTournamentAdmin function when the component mounts
  useEffect(() => {
    const isAdmin = isTournamentAdmin();
    setIsTournamentAdminStatus(isAdmin);
  }, []);

  return (
    <>
      <Center>
        <VStack gap={"10px"}>
          <Flex
            bgImage={{ base: biljartTafelLeegVert, sm: biljartTafelLeeg }}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            mt="40px"
            width={{
              base: "326px",
              sm: "422px",
              md: "634px",
              lg: "845px",
              xl: "1056px",
            }}
            h={{
              base: "634px",
              sm: "217px",
              md: "326px",
              lg: "435px",
              xl: "544px",
            }}
          >
            <Flex h="90%" w="95%" m="0 auto" alignSelf="center">
              <Grid
                templateAreas={` 
                    "blank1"
                    "form"
                    "blank2"
                  `}
                templateRows="1fr 3fr 1fr"
                w="100%"
              >
                <GridItem></GridItem>

                <GridItem gridArea="form" height="100%" width="100%">
                  <TournamentForm />{" "}
                  {/* comment this line and uncomment below to close the registration */}
                  {/* <VStack>
                    <Text fontFamily={"Arial"} fontWeight={900} pl={4} pr={4}>Toernooi enkelspel.</Text>
                    <Text fontFamily={"Arial"} fontWeight={900} pl={4} pr={4}>15 juli 2023, 13u00. The Corner, Ninove.</Text>
                    <Text fontFamily={"Arial"} fontWeight={900} pl={4} pr={4}>Inschrijvingen gesloten.</Text>
                  </VStack> */}
                </GridItem>
              </Grid>
            </Flex>
          </Flex>
          <Box
            backgroundColor="#139D08"
            width={{
              base: "326px",
              sm: "422px",
              md: "634px",
              lg: "845px",
              xl: "1056px",
            }}
            pb="10px"
          >
            <Heading textAlign={"center"} pb={3}>
              Bijkomende informatie
            </Heading>
            <List spacing={3} paddingLeft="20px" styleType="disc">
              <StyledListItem>
                Locatie: The Corner, Lavendelstraat 27, 9400 Ninove.
              </StyledListItem>
              <StyledListItem>Datum: Zaterdag 3 mei 2025.</StyledListItem>
              <StyledListItem>
                Plaatsen beperkt tot 20 personen, dus wees er snel bij !
              </StyledListItem>
              <StyledListItem>
                Aanmelden ter plaatse + bekendmaking wedstrijdschema: 13u00.
              </StyledListItem>
              <StyledListItem>
                Inschrijvingsgeld: €20.
                <br />
                Om alles in goede banen te leiden en iedereen een gelijke kans
                te geven op deelname, vragen wij je om je inschrijvingsgeld van
                20 euro te storten op rekeningnummer BE08 1030 7303 5513 vóór 20 april met vermelding van deze mededeling: Biljart + je
                volledige naam. Deze stap zorgt ervoor dat we het prijzengeld
                kunnen garanderen én dat we niemand onnodig op de wachtlijst
                hoeven te zetten.
                <br />
                LET OP: JE INSCHRIJVING IS PAS DEFINITIEF NA ONTVANGST VAN JE
                INSCHRIJVINGSGELD OP BOVENVERMELDE BANKREKENING.
                <br />
                Mocht je je toch bedenken en willen uitschrijven, dan kunnen we
                je inschrijvingsgeld alleen terugstorten als je dit vóór 20 april doet. Uitschrijven na deze datum betekent helaas dat
                het inschrijvingsgeld niet kan worden teruggegeven, tenzij een
                andere enthousiaste speler je plaats inneemt.
                <br />
                Dit prijzengeld wordt verdeeld onder de eerste 4 winnaars.
              </StyledListItem>
              <StyledListItem>
                Wedstrijdreglement volgens International 8-ball rules.
                Samenvatting van het reglement:{" "}
                <Link
                  href="/assets/files/Samenvatting_regels_competitie_2024.pdf"
                  target={"_blank"}
                >
                  KLIK HIER
                </Link>{" "}
                <br />
                (Volledig reglement, zie 'Reglement' in menu)
              </StyledListItem>
              <StyledListItem>
                <Text fontWeight="bold">Poulefase</Text>
                <UnorderedList>
                  <ListItem>
                    Aantal tafels: Er wordt gespeeld op 5 biljarttafels.
                  </ListItem>
                  <ListItem>
                    Poule-indeling: We hebben 5 poules van elk 4 personen.
                  </ListItem>
                  <ListItem>
                    Wedstrijden: Elke wedstrijd wordt gespeeld in een 'race to
                    2' formaat. Dit betekent dat de eerste speler die 2 spellen
                    wint, de wedstrijd wint.
                  </ListItem>
                  <ListItem>
                    De beste spelers gaan door naar de knock-out fase
                  </ListItem>
                </UnorderedList>
              </StyledListItem>
              <StyledListItem>
                <Text fontWeight="bold">Knock-out fase</Text>
                <UnorderedList>
                  <ListItem>
                    Deze fase wordt gespeeld tot er uiteindelijk 4 spelers
                    overblijven.
                  </ListItem>
                  <ListItem>
                    Deze 4 spelers spelen een wedstrijd in de halve finale.
                  </ListItem>
                  <ListItem>
                    De winnaars van elke halve finale wedstrijd spelen de
                    finale.
                  </ListItem>
                </UnorderedList>
              </StyledListItem>
              <StyledListItem>
                Verdere info of vragen over je inschrijving:{" "}
                <EmailIcon boxSize="1em" mr="2" />
                <Link
                  href="mailto:deschevestoot@hotmail.com?subject=Toernooi%202023-07-15"
                  color="white>"
                >
                  deschevestoot@hotmail.com
                </Link>
              </StyledListItem>
            </List>
          </Box>
          {/* IF THERE IS A LOGGED_IN USER FOR WHOM THE ROLES-ARRAY CONTAINS 'TOURNAMENTADMIN' OR 'ADMIN', SHOW THE TABLE WITH PLAYERS */}
          {isTournamentAdminStatus && (
            <Box>
              <TournamentplayersTable />
            </Box>
          )}
        </VStack>
      </Center>
    </>
  );
}
