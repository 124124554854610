import { Select, Text, Spinner, Box, Divider, Button } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { getPlayingdaysFromSeason } from "../../service/playingday";
import FontSize from "../../stylingObjects/FontSize";
import Playingday from "../../type/Playingday";
import Matches from "./Matches";
import { NavigateFunction, useNavigate } from "react-router-dom";
import User from "../../type/User";

interface Props {
  seasonId: number | null;
}

export const Playingdays: React.FC<Props> = ({ seasonId }) => {
  let navigate: NavigateFunction = useNavigate();
  const loggedInUser = JSON.parse(localStorage.getItem("User") || "null");

  const [playingdays, setPlayingdays] = useState<Playingday[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedPlayingdayId, setSelectedPlayingdayId] = useState<
    number | null
  >(null);
  const [selectedPlayingday, setSelectedPlayingday] =
    useState<Playingday | null>(null);

  const fetchPlayingdays = useCallback(async () => {
    try {
      setLoading(true);
      if (seasonId) {
        const fetchedPlayingdays = await getPlayingdaysFromSeason(seasonId);
        setPlayingdays(fetchedPlayingdays);


      // From here to line 55 new to auto select the first not-finished playingday
      // Automatically select the first playingday with is_finished = false
      const firstUnfinishedPlayingday = fetchedPlayingdays.find(
        (playingday: Playingday) => !playingday.is_finished
      );

      // if at least 1 playingday is not finished, that one is selected
      if (firstUnfinishedPlayingday) {
        setSelectedPlayingday(firstUnfinishedPlayingday);
        setSelectedPlayingdayId(firstUnfinishedPlayingday.playingday_id);
      } else {
        // If all playingdays are finished, select the last one
        const lastPlayingday = fetchedPlayingdays[fetchedPlayingdays.length - 1];
        if (lastPlayingday) {
          setSelectedPlayingday(lastPlayingday);
          setSelectedPlayingdayId(lastPlayingday.playingday_id);
        }
      }
    } else {
      setPlayingdays([]);
      setSelectedPlayingday(null);
      setSelectedPlayingdayId(null);
    }

      // } else {
      //   setPlayingdays([]);
      // }
    } catch (error: any) {
      console.error("Error fetching playingdays:", error.message);
    } finally {
      setLoading(false);
    }
  }, [seasonId]);

  useEffect(() => {
    fetchPlayingdays();
  }, [fetchPlayingdays]);

  const hasValidRole = (user: User | null) => {
    if (user === null) {
      return false;
    }
    const validRoles = ["ADMIN", "COMPETITIONADMIN", "SEASONADMIN", "MATCHADMIN"];
    return user.roles.some((role) => validRoles.includes(role));
  };

  return (
    <>
      <Text color="green" fontSize={{ ...FontSize.footer }}>
        Speeldagen
      </Text>
      {loading && <Spinner color="red" />}
      {!loading && playingdays.length > 0 && (
        <Select
          // new line to show the default selected playingday from the useCallback 'fetchPlayingdays'
          value={selectedPlayingdayId ?? ""}

          onChange={(e) => {
            const playingdayId = parseInt(e.currentTarget.value);
            const selectedPlayingday = playingdays.find(
              (playingday) => playingday.playingday_id === playingdayId
            );
            setSelectedPlayingday(selectedPlayingday ?? null);
            setSelectedPlayingdayId(playingdayId);
          }}
          color="green"
          bgColor="blackAlpha.900"
          fontFamily={"BitmapFont"}
          fontSize={{ ...FontSize.header }}
          w="50vw"
          maxW={{
            base: "220px",
            sm: "220px",
            md: "260px",
            lg: "350px",
            xl: "350px",
          }}
        >
          <option
            selected
            disabled
            value=""
            style={{ backgroundColor: "black" }}
          >
            Kies een speeldag
          </option>
          {playingdays.map((playingday, index) => (
            <option
              key={playingday.playingday_id}
              value={playingday.playingday_id}
              style={{ backgroundColor: "black" }}
            >
              {`${index + 1}.  ${
                playingday.date
                  ? new Date(playingday.date).toLocaleDateString("nl-NL")
                  : "-"
              } ${playingday.is_finished ? " (afgerond)" : ""}`}
            </option>
          ))}
        </Select>
      )}
      {!loading && playingdays.length === 0 && (
        <Text color="red">Geen speeldagen gevonden in dit seizoen</Text>
      )}
      {selectedPlayingdayId && <Matches playingday_id={selectedPlayingdayId} season_id={seasonId} />}
      {selectedPlayingday && (
        <>
         
          <Box
            mt="2"
            color="grey"
            maxW={{
              base: "380px",
              sm: "380px",
              md: "450px",
              lg: "600px",
              xl: "600px",
            }}
          >
            <Divider my="2" />
            <Text fontSize={{ ...FontSize.header }}>
              {selectedPlayingday.verslag}
            </Text>
            {hasValidRole(loggedInUser) && (
              <>
              <Button
              variant={"greenButton"}
              onClick={() => {
                navigate("/match/addedit", {
                  state: {
                    season_id: seasonId,
                    playingday_id: selectedPlayingday.playingday_id,
                    isAddMode: true,
                  },
                });
              }}
            >
              Match toevoegen
            </Button>
            <Button
              variant={"greenButton"}
              onClick={() => {
                navigate("/playingday/addedit", {
                  state: {
                    season_id: seasonId,
                    playingday_id: selectedPlayingday.playingday_id,
                    isAddMode: false,
                  },
                });
              }}
            >
              Speeldag bewerken
            </Button>
            </>
          )}
          </Box>
        </>
      )}
    </>
  );
};
